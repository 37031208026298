import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "./accessories.css"
import "../rolling-stock/catalog.css"

type AccessoriesPageProps = {
  data: {
    content: {
      pageSections: Array<{
        sectionName: string
        sectionItems: Array<{
          price: string
          name: string
          description: string
          image: {
            asset: {
              url: string
              title: string
            }
          }
        }>
      }>
    } | null
  }
}

const AccessoriesPage = ({ data }: AccessoriesPageProps) => (
  <Layout>
    <SEO title="Home" />
    <div className="container" style={{ flex: 1 }}>
      <div className="catalog">
        {data.content?.pageSections.map(pageSection => (
          <>
            <header className="catalog__section-header">
              {pageSection.sectionName}
            </header>
            <section>
              {pageSection.sectionItems.map(sectionItem => (
                <div className="catalog__item">
                  <img src={sectionItem.image?.asset?.url} />
                  <div className="catalog__item-contents">
                    <div className="catalog__item-name">{sectionItem.name}</div>
                    <div className="catalog__item-description">
                      {sectionItem.description}
                    </div>
                    <div className="catalog__item-price">
                      {sectionItem.price}
                    </div>
                  </div>
                </div>
              ))}
            </section>
          </>
        ))}
      </div>
    </div>
  </Layout>
)

export default AccessoriesPage

export const query = graphql`
  query AccessoriesPageQuery {
    content: sanityCatalogContent(pageId: { current: { eq: "accessories" } }) {
      pageSections {
        sectionItems {
          price
          name
          image {
            asset {
              url
              title
            }
          }
          description
        }
        sectionName
      }
    }
  }
`
